
import { Component, Vue } from 'vue-property-decorator'
import { Page } from '../../types/common'
import { PageType } from '../../types/question'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'QuestionList',
  filters: {
    isShowFilters (value: number) {
      return value === 0 ? '否' : value === 1 ? '是' : ''
    },
    isShowFiltersButton (value: number) {
      return value === 0 ? '显示' : value === 1 ? '隐藏' : ''
    }
  }
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    questionTitle: '',
    expertName: '',
    tagName: ''
  }

  private tableData: PageType[] = []
  private loading = false
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.question.selectQuestionByPage, {
      ...this.searchInfo,
      type: '2',
      page: this.page,
      size: this.size,
      isDel: 0
    })
      .then((res) => {
        this.tableData = res.list || []
        this.total = res.total || 0
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'questionDetail',
      params: { id }
    })
  }

  // 切换展示隐藏
  onShow (row: PageType) {
    const info = {
      questionId: row.questionId,
      isShow: row.isShow === 0 ? 1 : 0
    }
    this.$axios.post(this.$apis.question.updateQuestionIsShow, info).then(() => {
      this.getData()
      this.$message({
        message: '状态更新成功',
        type: 'success'
      })
    })
  }

  onAdd () {
    this.$router.push({
      name: 'questionAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'questionUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.question.deleteQuestion, {
          questionId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
